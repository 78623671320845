.error-boundary {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../images/web-error.png) top center no-repeat;
  background-size: 100%;
  div {
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.32rem;
    line-height: 1.5;
  }
}
