@import '../../style/variable.scss';
.loadingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 1rem);
}
.explain {
  padding-top: 10px;
  color: $text-color-main;
  font-size: 0.28rem;
  text-align: center;
}

.loading {
  position: relative;
  width: 0.6rem;
  height: 0.6rem;
  border: 2px solid rgba(50, 197, 281, 0.2);
  border-top-color: rgba(50, 197, 281, 1);
  border-right-color: rgba(50, 197, 281, 1);
  border-bottom-color: rgba(50, 197, 281, 1);
  border-radius: 100%;
  animation: circle infinite 1.5s linear;
  margin: 0 auto;
}
@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingPageBox {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1059;
  .flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .marsk {
    @include marsk();
  }
  .container {
    position: fixed;
    z-index: 1061;
    width: 3.2rem;
    background-color: #fff;
    min-height: 1rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0.4rem;
    border-radius: 0.1rem;
  }
}
