$text-color-main: rgba(38, 38, 38, 1);
$text-color-error: rgba(255, 77, 79, 1);
$text-color-placeholder: rgba(191, 191, 191, 1);
$text-color-blue: rgba(36, 211, 248, 1);
$text-color-gray: rgba(140, 140, 140, 1);
$bg-color-white: rgba(255, 255, 255, 1);
$bg-color-gray-form: rgba(245, 245, 245, 1);

$font-size-1: 0.2rem;
$font-size-2: 0.22rem;
$font-size-3: 0.24rem;
$font-size-4: 0.26rem;
$font-size-5: 0.28rem;
$font-size-6: 0.3rem;
$font-size-7: 0.32rem;
$font-size-8: 0.36rem;
$font-size-9: 0.4rem;

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@mixin image {
  width: 100%;
  height: 100%;
}

@mixin marsk {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 800;
}
@mixin settingArrow {
  width: 0.32rem;
  height: 0.32rem;
  background: url(../../images/my-arrow-icon.png) center center no-repeat;
  background-size: contain;
  display: block;
}

@mixin footerSafeArea {
  /* 以下顺序不能变 */
  /*兼容 IOS<11.2*/
  padding-bottom: constant(safe-area-inset-bottom);
  /*兼容 IOS>11.2*/
  padding-bottom: env(safe-area-inset-bottom);
}

@mixin sencondPage {
  height: 100vh;
  background-color: rgba(245, 245, 245, 1);
}


@mixin checkboxTriangle{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 .4rem .4rem;
  border-color: transparent transparent rgba(56, 207, 239, 1) transparent;
  display: none;
  &::after{
    content: "";
    display: block;
    position: absolute;
    right: .05rem;
    bottom: -.3rem;
    width: .12rem;
    height: .05rem;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    transform: rotate(-45deg);
  }
}

@mixin commonRadio {
  position: relative;
  color: $text-color-placeholder;
  cursor: pointer;
  padding: 0 0 0.16rem 0.1rem;
  box-sizing: border-box;
  i {
    width: 0.28rem;
    height: 0.28rem;
    display: inline-block;
    position: relative;
    border: 1px solid $text-color-placeholder;
    background-color: $bg-color-white;
    border-radius: 50%;
    right: 0.1rem;
    top: 0.06rem;
  }

  &.selected {
    i {
      background-color: $text-color-blue;
      border: 1px solid $text-color-blue;
      position: relative;
      &:after {
        content: '';
        width: 0.12rem;
        height: 0.06rem;
        border-top: 2px solid $bg-color-white;
        border-right: 2px solid $bg-color-white;
        display: block;
        position: absolute;
        left: 50%;
        transform-origin: 0 0;
        transform: rotate(135deg) translate(-50%, -50%);
        top: 48%;
      }
    }
  }
}

