@import 'template';
body {
  font-family: -apple-system, Helvetica, 'Hiragino Sans GB W3', arial, sans-serif;
  min-width: 320px;
  max-width: 750px;
  margin: 0 auto;
  color: $defaultFontColor;
  background-color: $defaultBackgroundColor;
  font-size: $defaultFontSize;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
