@import './global/reset.scss';
@import './global/common.scss';
@import './variable.scss';
@import './global/iphonex.scss';

// @import './global/.scss'

input::placeholder,
textarea::placeholder {
  color: rgba(191, 191, 191, 1);
  font-size: 0.28rem;
}

// ant-design-mobile默认样式覆盖
:root:root {
  --adm-color-primary: rgba(56, 207, 239, 1);
}


// grid price use
.common-dialog-confirm {
  .adm-center-popup-wrap {
    max-width: none;
    min-width: inherit;
    width: 6.54rem;
    box-sizing: border-box;
    .adm-dialog-title {
      padding: 0.32rem 0;
      font-size: $font-size-8;
    }
    .adm-dialog-content {
      color: $text-color-gray;
      font-size: $font-size-5;
      padding: 0 0.5rem 0.6rem;
    }
    .adm-dialog-footer {
      height: 0.96rem;
    }
    // .adm-dialog-action-row{
    //    button{
    //     &:nth-child(1) {
    //         color: $text-color-main;
    //     }
    //    }
    // }
  }
}

:global {
  .adm-list-item-content-prefix{
    width: 50%;
  }
}