@import './style/global.scss';
.account-form {
  .adm-list {
    &.adm-list-card {
      .adm-list-body {
        background: none;
        border-radius: 0;
        .adm-list-item {
          border: 1px solid rgba(221, 221, 221, 1);
          margin-bottom: 0.32rem;
          border-radius: 0.16rem;
          .adm-list-item-content {
            border-top: none;
          }
          // .adm-input{
          //     height: .92rem;
          // }
        }
      }
    }
  }
}



